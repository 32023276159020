import firebase from "firebase/app";
import 'firebase/firestore';

import 'firebase/storage'

// app = firebase

const firebaseConfig = {
    apiKey: "AIzaSyAxrUUTGSci1jywSFO54txuu9LA17w3YlQ",
    authDomain: "gpfirebase-caeee.firebaseapp.com",
    projectId: "gpfirebase-caeee",
    storageBucket: "gpfirebase-caeee.appspot.com",
    messagingSenderId: "683061524359",
    appId: "1:683061524359:web:fd9ea628ded71126a4c522"
  };

firebase.initializeApp(firebaseConfig)
const db = firebase.firestore()

const storage = firebase.storage();

export {db,firebase,storage}
