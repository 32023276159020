
import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  NavLink
} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.css';
import $ from 'jquery';
import viewport from 'jquery'
import './App.scss'
import {firebase,storage} from './firebase'
import React from 'react';



function App() {

  const [tareas, setTareas] = React.useState([])
  const [nombre, setNombre] = React.useState('')
  const [correo, setCorreo] = React.useState('')
  const [tel, setTel] = React.useState('')
  const [emailv, setEmailV] = React.useState('')
  const [nombrev, setNombreV] = React.useState('')
  const [urlcv, setUrlCv] = React.useState([])
  const [idpub, setID] = React.useState([])
  

  // ____________

  const agregar = async (e) => {
    e.preventDefault()
    if(!nombre.trim()){
        console.log('sin texto')
        return
    }
    console.log(nombre)
    try {
      const db = firebase.firestore()
      const nuevaTarea = {
          name: nombre,
          email: correo,
          phon: tel,
          fecha: Date.now()
      }
      const data = await db.collection('contacto').add({
        name: nombre,
        email: correo,
        phon: tel,
          fecha: Date.now()

        
      })
      setNombre([
          ...nombre,
          {id: data.id, ...nuevaTarea }
      ])
      setNombre('')
      setTel('')
      setCorreo('')
      window.alert("Datos Agregados Pronto nos pondremos en contacto !!!");
  } catch (error) {
      console.log(error)
  }
}
 
// ----------------------


const agregar1 = async (e) => {
  e.preventDefault()
  if(!nombrev.trim()){
      console.log('sin texto')
      return
  }
  console.log(nombre)
  try {
    const db = firebase.firestore()
    const nuevaTarea = {
        name: nombrev,
        email: emailv,
        urli: urlcv, 
        id: idpub,
        tel:tel,
        fecha: Date.now()
    }
    const data = await db.collection('vacantespost').add({
      name: nombrev,
      email: emailv,
      urli: urlcv, 
      tel:tel,
        fecha: Date.now()

      
    })
    setNombre([
        ...nombre,
        {id: data.id, ...nuevaTarea }
    ])
    setNombreV('')
    setEmailV('')
    setUrlCv('')
    setTel('')
    window.alert("Gracias por Postularte , pronto nos pondremos en contacto contigo  !!!");
} catch (error) {
    console.log(error)
}
}

 //_______________


 const seleccionarArchivo = async imagen =>{

  console.log(imagen.target.files[0])
  let r = (Math.random() + 1).toString(36).substring(7);
   
  try {

      const imagenRef= await storage.ref().child('cvs').child(r)
      await imagenRef.put(imagen.target.files[0])
      const imagenURL = await imagenRef.getDownloadURL()
      
       setUrlCv(imagenURL)
       window.alert("CV cargado" );
      


      
  } catch (error) {

      console.log(error)
      
  }
  

}



  // ____________

 

  React.useEffect(() => {

    const obtenerDatos = async () => {
        const db = firebase.firestore()
        try {
            const data = await db.collection('vacantesPando').get()
            const arrayData = data.docs.map(doc => ({id: doc.id, ...doc.data()}))
            console.log(arrayData) 
            setTareas(arrayData)     
        } catch (error) {
            console.log(error)
        }
    }
    obtenerDatos()

}, [])



  

  return (

   
    <div className="App">

<link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/font-awesome/4.6.0/css/font-awesome.min.css"/>





<div class="col-sm m-5 shadow-lg p-3 mb-5 bg-white rounded ">

<h4 style={{ color: 'blue'}}>Para más información:</h4>  
 <br />


  
 <h4>Comercial Confitería</h4>
      Grysel Zárate Jarquín   
     <a href="">55-4080-2951</a>     
     <br />
      gryselzarate@grupopando.com
      <br />
      <br />      

      Alfredo Villagomez Peñaloza    
      <a href="">55-8069-7567</a>
      <br />
      alfredovillagomez@grupopando.com
      
        <br />
        <hr />
  
        <h4>Comercial Conservas</h4>

      Ulises Rodríguez Otero   
     <a href="">55-2727-6056</a>    
     <br />
      ulisesrodriguez@grupopando.com
      
     <br />
     <hr />
     <h4>Comercial Vinos</h4>
Damian Tello   

<br />
damiantello@grupopando.com

<br />
    <hr />

      
      <h4>Recursos Humanos</h4>
      
      Karen Rosas Cruz   
     <a href="">55-7373-0397</a>   
     <br />
      karenrosas@grupopando.com
  
</div>


  



{/* <form  className=' '  style={{ backgroundImage: "url(https://grupopando.com/img/Content/Grupo-Pando-Exportaciones.png)", backgroundRepeat: "no-repeat",backgroundPosition: 'center' }} onSubmit={agregar}>
   
     
    <input 
        type="text" 
        className="form-control mb-3"
        placeholder='Nombre'
        value={nombre}
        onChange={e => setNombre(e.target.value)}
    />
    
       <input 
        type="text" 
        className="form-control mb-3"
        placeholder='Correo'
        value={correo}
        onChange={e => setCorreo(e.target.value)}
    />
       <input 
        type="text" 
        className="form-control mb-3"
        placeholder='Telefono'
        value={tel}
        onChange={e => setTel(e.target.value)}
    />
    

    <div class="container ">
  <div class="row">
    <div class="col-sm">
    <button 
        type='submit'
        className="btn btn-dark  "
    >
        Enviar
    </button>
    </div>
    <div class="col-sm">
       
    </div>
    <div class="col-sm  shadow-lg p-3 mt-5 mb-5 bg-white rounded text-justify">

     <h4 style={{ color: 'blue'}}>Para más información:</h4>  
      <br />
      
      
    ulisesrodriguez@grupopando.com
    <br />
    alfredovillagomez@grupopando.com
    <br />
   damiantello@grupopando.com
      

    </div>
  </div>
</div>

    <div>
  
    
  
    
    </div>
</form> */}












<link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css"/>
  <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.6.0/jquery.min.js"></script>
  <script src="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/js/bootstrap.min.js"></script>






{/* inicio modal vacantes */}

<div class="modal fade bd-example-modal-xl" tabindex="-1" role="dialog"
     aria-labelledby="myHugeModalLabel" aria-hidden="true">
  <div class="m-3">
    <div class="modal-content">
   <h1 className='m-5 text-center font-weight-bold' >Vacantes Disponibles</h1>    

   <h3 className='text-center'>Seccion en Mantenimiento</h3>   

    <ul className="">


      
            {/* {
                tareas.map(item => (



                  <div class="ca" key={item.id}>

<div class="card m-5">
  <div class="card-header">
  
  </div>
  <div class="card-body">
    <h3 class="card-title">{item.Nombre}</h3>
    <p>{item.ub}</p>
    <p class="card-text">Descripcion 
    <br />
     {item.desc}</p>

     <p class="lead m-1"><div className='text-info text-primary'>
      {item.r1}
       
    


       
      
      

      
      
       
     </div>   </p>
    
 <button type="button" onClick={ e => console.log(item.id)} class="btn btn-primary " data-toggle="modal" data-target=".bd-example-modal-x2">
 
 Llenar Formulario   
</button> 

  </div>
</div>
                               </div>

      
                ))
            } */}
            </ul>






    </div>
  </div>
</div>

{/* Fin modal vacantes */}

{/* postulacion inicio*/}

<div class="modal fade bd-example-modal-x2" tabindex="-1" role="dialog"
     aria-labelledby="myHugeModalLabel" aria-hidden="true">
  <div class="m-5 ">
    <div class="modal-content  ">

     
    <form className='m-5 bg-tranparent' onSubmit={ agregar1 }>
  <div class="form-group">
    <label for="exampleInputEmail1">Email address</label>
    <input type="email" class="form-control"   value={emailv}
        onChange={e => setEmailV(e.target.value)} id="exampleInputEmail1"  aria-describedby="emailHelp" placeholder="Enter email"/>
    
  </div>
  <div class="form-group">
    <label for="exampleInputPassword1">Nombre</label>
    <input type="Ingresa tu Nombre" value={nombrev}
        onChange={e => setNombreV(e.target.value)} class="form-control" id="exampleInputPassword1" placeholder="Ingresa tu Nombre"/>
  </div>
  <div class="form-group">
    <label for="exampleInputPassword1">Telefono</label>
    <input type="Ingresa tu Nombre" value={tel}
        onChange={e => setTel(e.target.value)} class="form-control" id="exampleInputPassword1" placeholder="(55)87546978"/>
  </div>
   
  
  
  <div className="input-group mb-3">
    
    <input type="file" className="btn btn-dark " id="inputGroupFile03" style={{display:'none'}}
    
    onChange = {e => seleccionarArchivo(e)}
  
    />
    <label className='btn btn-success btn-block' htmlFor="inputGroupFile03" >Carga tu CV</label>
  
    <div className='text-muted m-2'>*Opcional</div> 
    
    
  </div>
  
  
  <button type="submit" class="btn btn-primary ">Enviar</button>

  <p class="text-end text-muted mb-0 py-2">© 2022 Grupo Pando     <a data-toggle="modal" data-target="#exampleModal" href="" >Aviso de Privacidad</a>   </p>

</form>


    </div>
  </div>
</div>


{/* postulacion fin */}












     



     
      
    </div>
  );
}

export default App;
